import React,{useState,useEffect, useContext} from 'react';
import settings from '../settings.json'
import ImageSlider, { GenericSlider } from './utils/MySlider';
import SchedaTecnica from './images/scheda_tecnica.png'
import DettaglioFull from './DettaglioFull';
import LangContext from './Context/LangContext';

function DettaglioOnlyone(props) {
    const [placeSelected, setPlaceSelected] = useState(null)
    const [transition, setTransition] = useState(false)
    const [images, setImages] = useState([])
    const langs = useContext(LangContext);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
    }, [images])

    useEffect(() => {
        window.scrollTo(0, 0)
        setTransition(true)
        let place = settings.place.find(x=>x.id === Number(3))
        if(place){
            setPlaceSelected(place)
            setImages(place.images)
        }else{
            window.location.href = "/404"
        }
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
        setTransition(true)
        let place = settings.place.find(x=>x.id === Number(3))
        if(place){
            setPlaceSelected(place)
            setImages(place.images)
        }else{
            window.location.href = "/404"
        }
    }, [langs.lang])

    return (
        <div>
            <div style={{marginTop:"30px"}}>
                {placeSelected ? 
                <>
                    <div className="container">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{margin:"0 auto"}}>
                            <div>
                                <ImageSlider aspectratio={"16:9-min"} immagini={images} withThumb={false}></ImageSlider>
                            </div>
                        </div>
                    </div>
                    <div>
                        <DettaglioFull placeSelected={placeSelected} SchedaTecnica={SchedaTecnica} setImages={setImages} hiddenslider={false}></DettaglioFull>
                    </div>
                </>
                :null}
            </div>
        </div>
    );
}

export default DettaglioOnlyone;