import React,{useState,useEffect, useContext} from 'react';
import settings from '../settings.json'
import LangContext from './Context/LangContext';
import Dettaglio from './Dettaglio';
import ImageSlider from './utils/MySlider';

function Dettagliopage(props) {
    const [placeSelected, setPlaceSelected] = useState(null)
    const [transition, setTransition] = useState(false)
    const langs = useContext(LangContext);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    useEffect(() => {
        let id = props.match.params.id
        setTransition(true)
        let place = settings.place.find(x=>x.id === Number(id))
        if(place){
            setPlaceSelected(place)
        }else{
            window.location.href = "/404"
        }
    }, [props.match.params.id, langs.lang])

    return (
        <div>
            <div style={{marginTop:"30px"}}>
                {placeSelected ? 
                <div className="container">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{margin:"0 auto"}}>
                        <div>
                            <ImageSlider aspectratio={"16:9-min"} immagini={placeSelected.images} withThumb={false}></ImageSlider>
                        </div>
                        <Dettaglio placeSelected={placeSelected}></Dettaglio>
                    </div>
                </div>
                :null}
            </div>
        </div>
    );
}

export default Dettagliopage;