import React,{useState,useContext} from 'react';
import settings from '../settings.json'
import axios from 'axios'
import LangContext from './Context/LangContext';

function Contattipage(props) {
    const langs = useContext(LangContext); 
    const [descrizione, setDescrizione] = useState("")
    const [email, setEmail] = useState("")
    const [messaggio, setMessaggio] = useState("")
    const [nome, setNome] = useState("")
    const [messaggioinviato, setMessaggioinviato] = useState(null)

    function sumbitMessage(e){
        e.preventDefault()
        axios.post('/server/sendMessage.php',{
            fname:"["+nome+"] "+descrizione,
            message:messaggio,
            email:email,
        }).then((res)=>{
            setMessaggioinviato("Messaggio Inviato correttamente")    
            setTimeout(() => {
                setDescrizione("")
                setEmail("")
                setMessaggio("")
                setNome("")
                setMessaggioinviato(null)
                messaggioinviato(null)
            }, 3000);
            
        })
        
    }

    return (
        <div style={{marginTop:"15px",maxWidth:"2000px"}} className="container-fluid" >
            <div className="container">
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12" style={{margin:"0 auto"}}>
                    <div className="row">
                        {!messaggioinviato ?
                        <div className="col-lg-8 col-md-8 col-sm-7 col-xs-12">
                            <form onSubmit={(e)=>{sumbitMessage(e)}}>
                                <div style={{fontWeight:'bold',fontSize:"20px",marginBottom:"15px"}}>
                                    {langs.lang === "it" ? settings.information_request : settings.information_request_EN }
                                </div>
                                <div style={{fontWeight:'bold',fontSize:"16px",marginBottom:"10px"}} className="place-color">
                                {langs.lang === "it" ? settings.name_lastname : settings.name_lastname_EN }*
                                </div>
                                <div style={{marginBottom:"10px"}} >
                                    <input required type="text" value={nome} onChange={(e)=>setNome(e.target.value)} className="form-control" />
                                </div>
                                <div style={{fontWeight:'bold',fontSize:"16px",marginBottom:"10px"}} className="place-color">
                                {langs.lang === "it" ? settings.subject : settings.subject_EN }*
                                </div>
                                <div style={{marginBottom:"10px"}}>
                                    <input required type="text" value={descrizione} onChange={(e)=>setDescrizione(e.target.value)} className="form-control" />
                                </div>
                                <div style={{fontWeight:'bold',fontSize:"16px",marginBottom:"10px"}} className="place-color">
                                {langs.lang === "it" ? settings.your_email : settings.your_email_EN }*
                                </div>
                                <div style={{marginBottom:"10px"}}>
                                    <input required type="email" value={email} onChange={(e)=>setEmail(e.target.value)} className="form-control" />
                                </div>
                                <div style={{fontWeight:'bold',fontSize:"16px",marginBottom:"10px"}} className="place-color">
                                {langs.lang === "it" ? settings.message : settings.message_EN }*
                                </div>
                                <div style={{marginBottom:"10px"}}>
                                    <textarea required type="text" style={{height:"150px"}} onChange={(e)=>setMessaggio(e.target.value)} className="form-control" >
                                        {messaggio}
                                    </textarea>
                                </div>
                                <div>
                                    <button className="btn btn-primary">
                                    {langs.lang === "it" ? settings.send_request : settings.send_request_EN }
                                    </button>
                                </div>
                            </form>
                        </div>
                        :
                        <div className="col-lg-8 col-md-8 col-sm-7 col-xs-12" style={{textAlign:"center",fontWeight:'bold',fontSize:"20px",marginTop:"30px"}}> 
                            {messaggioinviato}    
                        </div>}
                    <div className="col-lg-4 col-md-4 col-sm-5 col-xs-12">
                        <div style={{fontWeight:'bold',fontSize:"20px",marginBottom:"15px"}}>
                        {langs.lang === "it" ? settings.contatti : settings.contatti_EN }
                        </div>
                        <div style={{marginBottom:"10px"}}>
                            <i className="fas fa-map-marker-alt place-color"></i> Via di Porta Pinciana, 4 <br/> 00187 Roma  - Italy
                            <br />
                            <br />
                            <small>P.IVA 12940710150</small>
                        </div>
                        <div style={{marginBottom:"10px"}}>
                            <i className="fas fa-envelope-open-text place-color"></i>  <a href="mailto:info@ripa73.it" style={{color:"inherit"}}>info@ripa73.it</a>
                        </div>
                        {settings.social_icon.map((icon,i)=>{
                            return(
                                <a key={i} href={icon.link} style={{color:"inherit"}}>
                                    <div className="row" >
                                        <div className="col-1">
                                            <i className={icon.className+" place-color"}></i> 
                                        </div>
                                        <div className="col-10" style={{paddingBottom:"2px"}}>
                                            <span >{icon.alt}</span>
                                        </div>
                                    </div>
                                </a>
                            )
                        })}
                    </div> 
                </div>
            </div>
        </div>
    </div>
    );
}

export default Contattipage;