import React,{ useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import settings from './settings.json'
import LangContext from './components/Context/LangContext'

function App(props) {

  const [height, setHeight] = useState(120)
  const [openMenu, setopenMenu] = useState(true)
  const [transition, setTransition] = useState(false)
  const [lang, setLang] = useState("it")
  
  function setPadding(height){
    setHeight(120+height)
  }
  function changeMenuState(){
    setTransition(true)
    setopenMenu(!openMenu)
  }
  useEffect(() => {
    if(localStorage.getItem("lang")){
      setLang(localStorage.getItem("lang"))
    }
  }, [])

  function setLanguage(lang){
    setLang(lang)
    localStorage.setItem("lang",lang)
  }

  return (
    <LangContext.Provider value={{lang:lang,setLang:setLanguage}}>
      <div className="container-fluid contenitore" style={{paddingBottom:height}}>
        <div className="row">
          <Header returnPadding={setPadding} changeMenuState={changeMenuState}></Header>
        </div>
        <div style={{marginBottom:"150px"}}>
          {props.children}
          <Footer></Footer>
        </div>
        <div className={transition ? "fade":""} onAnimationEnd={()=>setTransition(false)} style={{position:'fixed',bottom:"0",right:"0",top:"0",left:"0",backgroundColor:"rgba(198,198,198,0.6)",zIndex:998}} hidden={openMenu} onClick={()=>{changeMenuState()}}></div>
        <div className={transition ? "fade header-background":"header-background"} onAnimationEnd={()=>setTransition(false)} hidden={openMenu} style={{position:'fixed',bottom:"0",right:"0",top:"0",width:"250px",zIndex:999}}>
          <div style={{margin:"15px 0",textAlign:"right",color:"white",paddingRight:"15px"}}>
            <span style={{cursor:'pointer'}} onClick={()=>changeMenuState()}>X</span>
          </div>
          <div>
            <div style={{color:'white'}} className="menuitem">
              <Link to={"/"} onClick={()=>{changeMenuState()}}>HOME PAGE</Link>
            </div>
            {settings.place.map((place,i)=>{
              return(
                <div key={i} style={{color:'white'}} className="menuitem">
                  <Link to={place.url} onClick={()=>{changeMenuState()}}>{place.name}</Link>
                </div>
              )
            })}
            <div style={{color:'white'}} className="menuitem">
              <Link to={"/contatti"} onClick={()=>{changeMenuState()}}>CONTATTI</Link>
            </div>
            <div style={{color:'white'}} className="menuitem">
              <Link to={"/privacy-policy"} onClick={()=>{changeMenuState()}}>POLICY PRIVACY</Link>
            </div>
            <div style={{color:'white'}} className="menuitem">
              <Link to={"/cookie-policy"} onClick={()=>{changeMenuState()}}>COOKIE POLICY</Link>
            </div>
          </div>
          <div style={{textAlign:'center',padding:"10px",marginLeft:"15px"}} className="container">
              {settings.social_icon.map((icon,i)=>{
                  return(
                      <a key={i} href={icon.link} style={{color:"whitesmoke"}}><div className="row"><i className={icon.className} style={{color:"white",paddingLeft:"15px"}}></i> <span>{icon.alt}</span></div></a>
                  )
              })}
          </div>
        </div>
      </div>
    </LangContext.Provider>
  );
}

export default App;
