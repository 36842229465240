import React,{useContext} from 'react';
import logo_bottom from './images/logo_bottom.png'
import settings from '../settings.json'
import { Link } from 'react-router-dom';
import LangContext from './Context/LangContext';

function Footer(props) {
    const langs = useContext(LangContext);
    return (
        <div className="footer">
            <div className="container">
                <div className="d-none d-sm-block">
                    <div className="row">
                        <div className="col-3">
                            <a href="/"><img src={logo_bottom} style={{height:"100%",maxHeight:"150px"}} alt=""/></a>
                        </div>
                        <div className="col-4">
                            {settings.place.map((place,i)=>{
                                return(
                                    <div key={i} style={{paddingBottom:"5px"}}>
                                        <Link to={place.url} style={{color:'inherit'}}>
                                            <div style={{fontSize:"13px"}}>{place.name}</div>
                                            <div style={{fontSize:"11px",fontWeight:"bold"}}>
                                                {langs.lang === "it" ? place.place : place.place_EN }
                                            </div>
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="col-4">
                            <div style={{fontWeight:"bold",marginBottom:"15px"}}>
                                <Link to="/contatti" style={{color:"inherit"}}>{langs.lang === "it" ? settings.contatti : settings.contatti_EN }</Link></div>
                            <div style={{fontWeight:"bold",marginBottom:"15px"}}>
                                <Link to="/privacy-policy" style={{color:"inherit"}}>{langs.lang === "it" ? settings.info_privacy : settings.info_privacy_EN }</Link></div>
                            <div>
                                {settings.social_icon.map((icon,i)=>{
                                    return(
                                        <a key={i} href={icon.link} style={{color:"inherit"}}>
                                            <div className="row" >
                                                <div className="col-1">
                                                    <i className={icon.className}></i> 
                                                </div>
                                                <div className="col-10" style={{paddingBottom:"2px"}}>
                                                    <span >{icon.alt}</span>
                                                </div>
                                            </div>
                                        </a>
                                    )
                                })}
                            </div>
                            <div style={{marginTop:"10px"}}>
                            Via di Porta Pinciana, 4 - 00187 Roma
                            </div>
                            <div>
                                <a style={{color:"inherit"}} href="mailto:info@ripa73.it">info@ripa73.it</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-block d-sm-none">
                    <div className="row" style={{margin:0 ,padding:0}} >
                        <div className="col-2" >
                            <a href="/"><img src={logo_bottom} style={{height:"100%",maxHeight:"40px"}} alt=""/></a>
                        </div>
                        <div className="col-8">
                        Via di Porta Pinciana, 4 - 00187 Roma
                        </div>
                        {/* <div className="col-2" style={{textAlign:'center',marginTop:"5px",marginLeft:0}}>
                            {settings.social_icon.map((icon,i)=>{
                                return(
                                    <a key={i} href={icon.link} style={{color:"inherit"}}>
                                        <div className="row" >
                                            <div className="col-1">
                                                <i className={icon.className}></i> 
                                            </div>
                                            <div className="col-10" style={{paddingBottom:"2px"}}>
                                                <span >{icon.alt}</span>
                                            </div>
                                        </div>
                                    </a>
                                )
                            })}
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="container" style={{fontSize:"10px",textAlign:"center",marginTop:"10px"}}>
                <div>
                    <b>RIPA 73 SRL</b> - VIA DI PORTA PINCIANA, 4 - 00187 ROMA​ - SDI: M5UXCR1 - P.IVA 12940710150 | copyright © | <a style={{color:"inherit"}} href="/cookie-policy">COOKIE POLICY</a>
                </div>
                <div>
                    Powered By <a style={{color:"inherit"}} href="http://www.thelabitalia.it/"><b>The Lab sas</b></a>
                </div>
            </div>
        </div>
    );
}

export default Footer;