import React,{useState,useEffect, useContext} from 'react';
import ImageAspectRatio from './utils/ImageAspectRatio';
import settings from '../settings.json'
import { GenericSlider } from './utils/MySlider';
import Dettaglio from './Dettaglio';
import DettaglioFull from './DettaglioFull';
import SchedaTecnica from './images/scheda_tecnica.png'
import LangContext from './Context/LangContext';
import { Carousel } from 'react-bootstrap';


function Homepage(props) {
    const [placeSelected, setPlaceSelected] = useState(null)
    const [transition, setTransition] = useState(true)
    const langs = useContext(LangContext);
    const [places,setPlace] = useState(null)

    useEffect(() => {
        setPlace(settings.place.filter(x=>x.lang === "it"))
        if(placeSelected){
            changePlace(placeSelected.id)
        }
    }, [langs.lang])
    
    function changePlace(id){
        if(!placeSelected || placeSelected.id!==id){
            setTransition(true)
            let place = places.find(x=>x.id === id)
            setPlaceSelected(place)
        }
    }

    return (
        <>
            <div style={{marginTop:"15px",maxWidth:"2000px"}} className="container-fluid">
                <div className="d-none d-md-block">
                    <div className="row">
                        {places && places.map((place,i)=>{
                            return(
                                <div key={i} className="col-4 place-menu " onClick={()=>{changePlace(place.id)}}>
                                    <div className="immagine-home">
                                        <ImageAspectRatio src={place.image} aspectratio={"16:9"}></ImageAspectRatio>
                                    </div>
                                    <div className="homepage-detail">
                                        <div className="home-name">
                                            {place.name}
                                        </div>
                                        <div className="home-place place-color">
                                            {langs.lang === "it" ? place.place : place.place_EN }
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div style={{marginTop:"30px"}}>
                        {placeSelected ? 
                        <div className="container">
                            <div className="col-10" style={{margin:"0 auto"}}>
                                <div className={transition ? "fade homepage-detail":"homepage-detail"} onAnimationEnd={()=>setTransition(false)}>
                                    {placeSelected.isOnlyOne ? 
                                        <DettaglioFull placeSelected={placeSelected} hiddenslider={true} SchedaTecnica={SchedaTecnica} showgallerybutton={true}></DettaglioFull>
                                        :
                                        <Dettaglio placeSelected={placeSelected} showgallerybutton={true}></Dettaglio>
                                    }
                                </div>
                            </div>
                        </div>
                        :null}
                    </div>
                </div>
   
                <div className="d-block d-sm-none">
                    <div className="homepage-detail" style={{marginBottom:"15px"}}>
                    <Carousel>
                        {places && places.map((place,i)=>{
                            return(
                                <Carousel.Item interval={5000}>
                                    <a href={place.url}>
                                        <div className="immagine-home">
                                            <ImageAspectRatio src={place.image} aspectratio={"3:2"}></ImageAspectRatio>
                                        </div>
                                        <div style={{textAlign:'center'}}>
                                            <div className="home-name" style={{fontWeight: "bold", fontSize: "30px",color:'black'}}>{place.name}</div>
                                            <div className="home-place place-color">{place.place}</div>
                                        </div>
                                    </a>
                                </Carousel.Item>
                                // <div key={i} onClick={()=>{changePlace(place.id)}}>
                                //     <div className="immagine-home">
                                //         <ImageAspectRatio src={place.image} aspectratio={"3:2"}></ImageAspectRatio>
                                //     </div>
                                //     <div style={{marginTop:"30px"}}>
                                //         {place ? 
                                //         <div className="container">
                                //             {place.isOnlyOne ? 
                                //                 <DettaglioFull placeSelected={place} hiddenslider={true} SchedaTecnica={SchedaTecnica} showgallerybutton={true}></DettaglioFull>
                                //                 :
                                //                 <Dettaglio placeSelected={place} showgallerybutton={true}></Dettaglio>
                                //             }
                                //         </div>
                                //         :null}
                                //     </div>
                                // </div>
                            )
                        })}
         
                        
                        {/* <Carousel.Item interval={1500}>
                            <img
                            className="d-block w-100"
                            src="https://via.placeholder.com/350x150"
                            alt="Second slide"
                            />
                            <Carousel.Caption>
                            <h3>Second slide label</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item interval={1500}>
                            <img
                            className="d-block w-100"
                            src="https://via.placeholder.com/350x150"
                            alt="Third slide"
                            />
                            <Carousel.Caption>
                            <h3>Third slide label</h3>
                            <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                   */} </Carousel>
                   </div>

                    {/* <GenericSlider arrows={false} dots={false} autoplay={false} slideToShow={1}>
                        {places && places.map((place,i)=>{
                            return(
                                <div key={i} onClick={()=>{changePlace(place.id)}}>
                                    <div className="immagine-home">
                                        <ImageAspectRatio src={place.image} aspectratio={"3:2"}></ImageAspectRatio>
                                    </div>
                                    <div style={{marginTop:"30px"}}>
                                        {place ? 
                                        <div className="container">
                                            {place.isOnlyOne ? 
                                                <DettaglioFull placeSelected={place} hiddenslider={true} SchedaTecnica={SchedaTecnica} showgallerybutton={true}></DettaglioFull>
                                                :
                                                <Dettaglio placeSelected={place} showgallerybutton={true}></Dettaglio>
                                            }
                                        </div>
                                        :null}
                                    </div>
                                </div>
                            )
                        })}
                    </GenericSlider>  */}
                </div>
            </div>
            
            <div>
                {!placeSelected ? 
                    <div className="container">
                        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12" style={{margin: "0 auto"}}>
                            <div className={transition ? "fade homepage-detail":"homepage-detail"} onAnimationEnd={()=>setTransition(false)}>
                            <div dangerouslySetInnerHTML={{__html: langs.lang === "it" ? 
                                    settings.hometext_it 
                                    : 
                                    settings.hometext_en}}/>
                            </div>
                        </div>   
                    </div>
                :null}
            </div>
        </>
    );
}

export default Homepage;