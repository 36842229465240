import React from 'react';

function ImageAspectRatio(props) {
    let paddingBottom = "100%"
    switch (props.aspectratio) {
        case "1:1":
            paddingBottom="100%"
            break;
        case "16:9-min":
            paddingBottom = "50.00%"
            break;
        case "16:9":
            paddingBottom = "56.25%"
            break;
        case "4:3":
            paddingBottom = "75%"
            break;
        case "3:2":
            paddingBottom = "66.66%"
            break;
        case "8:5":
            paddingBottom = "62.5%"
            break;
        default:
            paddingBottom = "100%"
            break;
    }
    let wrapperStyle={paddingBottom: paddingBottom,position:"relative",width:"100%",maxHeight:"400px",height:"100%",background: "url("+props.src+") no-repeat center",backgroundSize: "cover"}
    return (
        <div key={props.src} style={wrapperStyle}>
            {props.description ? 
                <div className="vertical-center box-label-shop color-base">
                    <>{props.description}</>
                </div>
            :null}
        </div>
    );
}
export default ImageAspectRatio;