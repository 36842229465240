import React,{useContext} from 'react';
import ImageAspectRatio from './utils/ImageAspectRatio';
import { GenericSlider } from './utils/MySlider';
import LangContext from './Context/LangContext';
import settings from '../settings.json'

function DettaglioFull(props) {
    const langs = useContext(LangContext);
    return (
        props.placeSelected ? 
        <>
            <div className="container" hidden={!props.hiddenslider}>
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-12" style={{margin:"0 auto"}}>
                    <div className="home-name" style={{fontWeight:"bold",fontSize:"30px"}}>
                        {props.placeSelected.name} {props.placeSelected.externalUrl ? <span><a style={{fontSize:"15px"}} className="place-color" href={props.placeSelected.externalUrl}><i className="fas fa-external-link-alt"></i></a></span> : null}
                    </div>
                    <div className="home-place place-color" style={{fontSize:"20px"}}>
                        {langs.lang === "it" ? props.placeSelected.place : props.placeSelected.place_EN }
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 col-md-10 col-sm-6 col-xs-6" style={{margin:"0 auto"}}>
                        <div className={"homepage-detail"}>
                            <div className="row" style={{textAlign:"center",marginBottom:"20px"}}>
                                {props.placeSelected.detail_meausre && props.placeSelected.detail_meausre.map((dm,i)=>{
                                    return(
                                        <div key={i} className="col-lg-3 col-md-3 col-sm-3 col-xs-6">
                                            <div className="oo-detail-numeric">{dm.numeric}</div>
                                            <div className="oo-detail-line"></div>
                                            <div className="oo-detail-measure">{dm.measure}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
            <div className="container">
                <div className="col-lg-12 col-md-12 col-sm-10 col-xs-12" style={{margin:"0 auto"}}>
                    <div className="home-text" style={{textAlign:'center'}}>
                        <div dangerouslySetInnerHTML={{__html: langs.lang === "it" ?  props.placeSelected.text : props.placeSelected.text_EN}} />
                    </div>
                </div>
            </div>
            <div className="container-fluid" hidden={props.hiddenslider}>
                <div className="" style={{margin:"0 auto",textAlign:"center",paddingTop:"15px"}}>
                    <div  style={{cursor: "pointer"}}>
                        <GenericSlider arrows={false} dots={false} autoplay={true} slidesToShow={4} inifinite={true}>
                            {props.placeSelected.sub_images.map((si,i)=>{
                                return(
                                    <div key={i} className="col-11" onClick={()=>props.setImages(si.images)}>
                                        <div className="immagine-home">
                                            <ImageAspectRatio src={si.file} aspectratio={"3:2"}></ImageAspectRatio>
                                        </div>
                                        <div className="homepage-detail">
                                            <div className="home-name">
                                                {langs.lang !== "it" && si.title === "MENU DI BORDO" ?"ON-BOARD MENU" : si.title}
                                            </div>
                                            <div className="home-place place-color">
                                                {si.subtitle}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </GenericSlider>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className={"homepage-detail"}>
                    {/* <div className="home-name" style={{fontWeight:"bold",fontSize:"30px"}} hidden={props.hiddenslider}>
                        {props.placeSelected.name}
                    </div> */}
                    {/* <div className="home-place place-color" style={{fontSize:"20px"}}>
                        {props.placeSelected.subname}
                    </div>
                    <div>
                        <img src={props.SchedaTecnica} style={{width:"100%"}} alt=""/>
                    </div> */}
                </div>

                {props.showgallerybutton ? 
                    <span>
                        <a style={{fontSize:"15px",marginTop:"30px", marginRight:"10px"}} className="place-color" href={props.placeSelected.url}>
                            <button  style={{marginTop:"15px"}} type="button" className="btn btn-outline-info">
                                {langs.lang === "it" ? settings.go_to_gallery : settings.go_to_gallery_EN }
                            <i className="space-left fas fa-external-link-alt"></i></button>
                        </a>
                    </span>
                    :null}
       
            </div>
        </>
        :null
    );
}

export default DettaglioFull;