import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Homepage from './components/Homepage';
import NotFoundPage from './components/NotFoundPage';
import { Redirect, BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Contattipage from './components/Contattipage';
import Privacypage from './components/Privacypage';
import Dettagliopage from './components/Dettagliopage';
import DettaglioOnlyone from './components/DettaglioOnlyone';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Cookiepage from './components/Cookiepage';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route exact path={"/"} render={(props) => <App><Homepage></Homepage></App>} />
        <Route exact path={"/contatti"} render={(props) => <App><Contattipage></Contattipage></App>} />
        <Route exact path={"/privacy-policy"} render={(props) => <App><Privacypage></Privacypage></App>} />
        <Route exact path={"/cookie-policy"} render={(props) => <App><Cookiepage></Cookiepage></App>} />
        <Route exact path={"/only-one/3"} render={(props) => <App><DettaglioOnlyone {...props}></DettaglioOnlyone></App>} />
        <Route exact path={"/:url/:id"} render={(props) => <App><Dettagliopage {...props}></Dettagliopage></App>} />
        <Route path="/404" render={(props) => <App><NotFoundPage /></App>} />
        <Redirect to="/404" />
      </Switch>
      </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
