import React from 'react';

function Cookiepage(props) {
    return (
        <div style={{textAlign:'center'}}>
            Cookie policy
        </div>
    );
}

export default Cookiepage;