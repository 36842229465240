import React from 'react';

function NotFoundPage(props) {
    return (
        <div style={{textAlign:'center'}}>
            <div className="page-not-found">
                <div style={{fontWeight:'bold'}}>
                    404
                </div>
                <div>
                    Il contenuto cercato non è più disponibile
                </div>
            </div>

        </div>
    );
}

export default NotFoundPage;