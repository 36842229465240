import React,{useContext} from 'react';
import LangContext from './Context/LangContext';
import settings from '../settings.json'

function Dettaglio(props) {  
    const langs = useContext(LangContext);  
    return (
        <>
            <div className={"homepage-detail"}>
                <div className="home-name" style={{fontWeight:"bold",fontSize:"30px"}}>
                    {props.placeSelected.name} {props.placeSelected.externalUrl ? <span><a style={{fontSize:"15px"}} className="place-color" href={props.placeSelected.externalUrl}><i className="fas fa-external-link-alt"></i></a></span> : null}
                </div>
                <div className="home-place place-color" style={{fontSize:"20px"}}>
                    {langs.lang === "it" ? props.placeSelected.place : props.placeSelected.place_EN }
                </div>
                <div className="home-text">
                    <div dangerouslySetInnerHTML={{__html: langs.lang === "it" ?  props.placeSelected.text : props.placeSelected.text_EN}} />
                </div>
                {props.showgallerybutton ? 
                    <span>
                        <a style={{fontSize:"15px",marginTop:"30px", marginRight:"10px"}} className="place-color" href={props.placeSelected.url}>
                            <button type="button" className="btn btn-outline-info" style={{marginTop:"15px"}}>
                                {langs.lang === "it" ? settings.go_to_gallery : settings.go_to_gallery_EN }
                            <i className="space-left fas fa-external-link-alt"></i></button>
                        </a>
                    </span>
                :null}
                {props.placeSelected.externalUrl ? 
                    <span >
                        <a style={{fontSize:"15px",marginTop:"30px"}} target="_blank" className="place-color" href={props.placeSelected.externalUrl}>
                            <button type="button" style={{marginTop:"15px"}} className="btn btn-outline-info">
                                {langs.lang === "it" ? settings.go_to_site : settings.go_to_site_EN }
                            <i className="space-left fas fa-external-link-alt"></i></button>
                        </a>
                    </span>
                :null}
            </div>
        </>
    );
}

export default Dettaglio;