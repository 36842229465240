import React,{useState,useEffect,useRef} from "react";
import Slider from "react-slick";
import ImageAspectRatio from "./ImageAspectRatio";

let placeholderimage = 'https://lh3.googleusercontent.com/proxy/qcaaUkbcopGvjPW5_-_wEiJu6jryzgVNhW59x4ZQlCfJ-BMW-1mwNCp2ZoDMiXeD7tHMpJwJMNgm8Xyk66dDiF-fqPFxBBp9L5P_IZATBbaiceQXo2m-N0uCp8V899sRYcOAiEBTIGEm';

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className="arrow-slider arrow-slide-left" onClick={onClick}>
      {/* <img src={leftArrow} alt=""/> */}
      <i className="fas fa-chevron-left"></i>
    </div>
  );
}
function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className="arrow-slider arrow-slide-right" onClick={onClick}>
      <i className="fas fa-chevron-right"></i>
    </div>
  );
}


export default function ImageSlider(props) {
    const withThumb=props.withThumb
    const immagini= props.immagini
    const aspectratio = props.aspectratio
    const [settings, setSettings] = useState({settings:{
        initialSlide:0,
        slidesToShow: 1,
        slidesToScroll: 1,
        // centerMode: true,
        arrows: true,
        dots: false,
        autoplay: true,
        autoplaySpeed: 3000,
        infinite: true,
        adaptiveHeight: true, //add this line
        nextArrow:<NextArrow />,
        prevArrow:<PrevArrow />
      }})

      useEffect(() => {
        setSettings({settings:{
          initialSlide:0,
          slidesToShow: 1,
          slidesToScroll: 1,
          // centerMode: true,
          arrows: true,
          dots: false,
          autoplay: true,
          autoplaySpeed: 3000,
          infinite: true,
          adaptiveHeight: true, //add this line
          nextArrow:<NextArrow />,
          prevArrow:<PrevArrow />,
        }})
      }, [props.immagini])

      function goToSlide(n){
        setSettings({...settings,slideIndex:n})
      }
      const sliderRef = useRef();
      const handleOnClick = index => {
        sliderRef.current.slickGoTo(index);
      };
    return (
      <div style={{paddingBottom:"15px"}}>
        {immagini.length > 0?
        <>
          <Slider {...settings.settings} style={{width:"100%"}} ref={sliderRef} className="myslidercontrol">
            {immagini.sort((a,b)=>{return a.position-b.position}).map((immagine,i)=>{
              return(
                  <ImageAspectRatio key={i} aspectratio={aspectratio}  src={immagine.file} alt={immagine.alt}></ImageAspectRatio> 
              )
            })}
            {immagini.length===0 ? <ImageAspectRatio aspectratio={aspectratio}  src={placeholderimage} alt={""}></ImageAspectRatio>:null}
          </Slider>
          <div>
          {withThumb && immagini.length > 1 ?  
            <div className="row">
              {immagini.sort((a,b)=>{return a.position-b.position}).map((immagine,i)=>{
                return(
                  <div key={i} className="col-2" style={{margin:"1px 0px 1px 0px",paddingRight:"0",paddingTop:"5px"}}>
                    <div style={{border:"1px solid rgb(135, 18, 38, 0.5)",cursor:"pointer"}} onClick={()=>handleOnClick(i)}>
                      <ImageAspectRatio aspectratio={aspectratio} src={immagine.file} alt={immagine.alt} onClick={()=>goToSlide(i+1)}></ImageAspectRatio>
                    </div>
                  </div>
                )
              })}
            </div> 
          : null}</div>
        </>
        :null}
        
      </div>
    )
}

export function GenericSlider(props) {
  let maxHeight = props.maxHeight ? props.maxHeight : "400px";
  let slidesToShow = props.slidesToShow ? props.slidesToShow : 1;
  var settings = {
    initialSlide:0,
    slidesToShow: slidesToShow,
    autoplay: props.autoplay,
    slidesToScroll: 1,
    speed:1500,
    arrows: props.arrows,
    dots: props.dots,
    adaptiveHeight: true, //add this line
    nextArrow:<NextArrow />,
    prevArrow:<PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
    };

  return (
      <Slider {...settings}>
        {props.children}
      </Slider>
  )
}