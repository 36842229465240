import React,{useEffect, useRef,useContext} from 'react';
import LangContext from './Context/LangContext';
import logo_full from './images/logo_full.png'
import logo_mobile from './images/logo_mobile.png'
import useResizeObserver from "use-resize-observer";
import './modalpanelslide.css'
import Panino from './images/Panino.png'
// https://bootsnipp.com/snippets/D0aq4

function Header(props) {
    const header = useRef();
    const { width, height } = useResizeObserver({ ref: header });
    const langs = useContext(LangContext);

    useEffect(() => {
        props.returnPadding(height)
    }, [props,height])

    return (
        <div className="header-background" style={{width:"100%"}} ref={header}>
            <div className="container" style={{textAlign:"left"}}>
                <div className="row" style={{padding:"0 30px",margin:0}}>
                    <div className="col-10">
                        <a href="/">
                            <img className="d-none d-sm-block" src={logo_full} alt="" style={{maxHeight:"120px",maxWidth:"100%"}}/>
                            <img className="d-block d-sm-none" src={logo_mobile} alt="" style={{maxHeight:"120px",maxWidth:"185px"}}/>
                        </a></div>
                    <div className="col-2 d-flex align-items-center justify-content-end">
                        <img src={Panino} style={{height:"100%",maxHeight:"30px",cursor:"pointer",minWidth:"40px"}} onClick={()=>props.changeMenuState()} alt=""/>
                        <div style={{paddingLeft:"15px",color:'whitesmoke',fontSize:'13px'}}>
                            <span style={ langs.lang === "it" ? {fontWeight:'bold',cursor:"pointer"} : {color:"lightgray",cursor:"pointer"}} onClick={()=>{langs.setLang("it")}}>IT</span> 
                            <br/>
                            <span style={ langs.lang === "en" ? {fontWeight:'bold',cursor:"pointer"} : {color:"lightgray",cursor:"pointer"}} onClick={()=>{langs.setLang("en")}}>EN</span></div>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default Header;